import backgroundInitial from "@/contents/img/landing/Rampver Online Topfold.png";
import backgroundInitialWebp from "@/contents/img/landing/Rampver Online Topfold.webp";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { PrivacyPolicyLink } from "@/components/PrivacyPolicy";
import AlertBox from "@/components/Alert";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import PasswordField from "@/components/ValidatedFields/PasswordField";
import CheckboxField from "@/components/ValidatedFields/CheckboxField";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../../App.css";

import { Component } from "react";
import SignUpFunctions from "@/components/Signup/function";
import Validators from "@/components/Helpers/validators";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { withFirebase } from "@/components/Firebase";
import Helpers from "@/components/Helpers";

import * as ROUTES from "@/constants/route";
import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { db } from "@/components/Firebase/firebase";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  mobileNumber: "",
  countryCode: "63",
  preferredFA: "",
  agreePolicy: false,
  showPass: false,
  showConfirmPass: false,
  isValid: false,
  loaded: false,
  submitDisabled: false,
  toggleAlert: false,
  severity: "warning",
  messageAlert: "",
  errors: null,
  youtubePlaylist: { data: null },
  youtubeId: "JL27zluj_qQ",
  referralCode: null,
  supportWebp: true,
};

const checkWebPSupport = (feature = "lossy", callback) => {
  var kTestImages = {
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
  };
  var img = new Image();
  img.src = "data:image/webp;base64," + kTestImages[feature];
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
};

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.fb = this.props.firebase;
    this.signUpFunctions = new SignUpFunctions();
    this.validators = new Validators();
    this.helpers = new Helpers();

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickShowPassword = this.onClickShowPassword.bind(this);
    this.onMouseDownPassword = this.onMouseDownPassword.bind(this);
    this.onCopyPasteCut = this.onCopyPasteCut.bind(this);
    this.onClickConfirmShowPassword =
      this.onClickConfirmShowPassword.bind(this);
    this.onMouseDownConfirmPassword =
      this.onMouseDownConfirmPassword.bind(this);
    this.onMobileNumber = this.onMobileNumber.bind(this);
    this.onClickAgreePolicy = this.onClickAgreePolicy.bind(this);
  }

  componentDidMount() {
    try {
      const referralCode = this.props.match.params.referralCode;
      this.setState({ referralCode });

      checkWebPSupport("lossy", (isSupported) => {
        this.setState({
          supportWebp: isSupported
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  // verifies referral code if it exists, returns referrer id
  // if referral code does not exist, return null
  onVerifyReferralCode(referralCode) {
    return new Promise((resolve, reject) => {
      if (!referralCode) {
        resolve(null);
      }

      const referrersRef = collection(db, "referrers");
      const q = query(
        referrersRef,
        where("referralCode", "==", referralCode),
        limit(1)
      );

      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.empty) {
          reject("Referral code not found.");
        } else {
          querySnapshot.forEach((doc) => {
            resolve(doc.id);
          });
        }
      });
    });
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validators.changePasswordValue(this.state.password);
    });
  };

  onSignUp(email, password) {
    let promise = new Promise((resolve, reject) => {
      this.fb
        .doCreateUserWithEmailAndPassword(email, password)
        .then((res) => {
          resolve(res.user.uid);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  onAddUser(user) {
    let promise = new Promise((resolve, reject) => {
      this.signUpFunctions
        .doAddUserData(user)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  onCheckUser(email) {
    let promise = new Promise((resolve, reject) => {
      this.signUpFunctions
        .doCheckUser(email)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  onPasswordChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validators.changePasswordValue(this.state.password);
    });
  };

  showErrorMessage(message) {
    this.setState({
      loading: false,
      submitDisabled: false,
      toggleAlert: true,
      severity: "error",
      messageAlert: message,
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true, submitDisabled: true }, () => {
      const {
        email: _email,
        password,
        firstName,
        lastName,
        mobileNumber,
        preferredFA,
        agreePolicy,
        referralCode,
      } = this.state;
      if (!agreePolicy)
        return this.setState({
          loading: false,
          submitDisabled: false,
          toggleAlert: true,
          severity: "error",
          messageAlert: "Invalid Data.",
        });
      const email = _email.toLowerCase();
      this.onVerifyReferralCode(referralCode)
        .then((referrerId) => {
          this.onSignUp(email, password)
            .then((res) => {
              let user = {
                useruid: res,
                firstName: firstName,
                lastName: lastName,
                mobileNumber: `+${mobileNumber}`,
                emailAddress: email,
                preferredFA: preferredFA,
                referrerId,
              };
              return this.onAddUser(user);
            })
            .then((res) => {
              if (!res.data.ok) throw new Error(res.data.message);

              window.location.href = ROUTES.SIGN_UP_LANDING;
              this.setState({
                loading: false,
                submitDisabled: false,
              });
            })
            .catch((err) => {
              if (err.code === "auth/email-already-in-use") {
                let userEmail = { email };
                this.onCheckUser(userEmail)
                  .then((result) => {
                    if (result.data.ok) {
                      if (
                        result.data.data.verified === false &&
                        result.data.data.hasEntry === false
                      )
                        this.onSubmit(event);
                      else if (result.data.data.verified === true)
                        this.showErrorMessage(
                          "You are already a verified user, please login!"
                        );
                      else if (result.data.data.hasEntry === true)
                        this.showErrorMessage(
                          "You already have an existing account. Kindly check your email inbox to verify your account and proceed to client application."
                        );
                      else this.showErrorMessage("This email is already taken");
                    } else this.showErrorMessage(result.data.message);
                  })
                  .catch((err) => {
                    this.showErrorMessage(err);
                  });
              } else {
                this.showErrorMessage(err.message);
              }
            });
        })
        .catch(() => {
          this.showErrorMessage("Failed to verify referral code");
        });
    });
  };

  onMobileNumber = (value) => {
    this.setState({ mobileNumber: value });
  };

  onClickAgreePolicy = (event) => {
    this.setState({ agreePolicy: event.target.checked });
  };

  onCopyPasteCut = (event) => {
    event.preventDefault();
  };

  onClickShowPassword = () => {
    this.setState({ showPass: true });
  };

  onMouseDownPassword = () => {
    this.setState({ showPass: false });
  };

  onClickConfirmShowPassword = () => {
    this.setState({ showConfirmPass: true });
  };

  onMouseDownConfirmPassword = () => {
    this.setState({ showConfirmPass: false });
  };

  validator = (e) => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      mobileNumber,
    } = this.state;
    const errors = {};
    switch (e.target.name) {
      case "firstName":
        if (firstName === "") {
          this.setState({ firstNameError: "Please input first name." });
        } else {
          this.setState({ firstNameError: "" });
        }
        break;
      case "lastName":
        if (lastName === "") {
          this.setState({ lastNameError: "Please input last name." });
        } else {
          this.setState({ lastNameError: "" });
        }
        break;
      case "email":
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
          this.setState({ emailError: "Please input a valid email address." });
        } else {
          this.setState({ emailError: "" });
        }
        break;
      case "password":
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,64}$)"
        );

        if (confirmPassword.length <= 0) {
          if (!strongRegex.test(password)) {
            this.setState({
              passwordError:
                "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
            });
          } else {
            this.setState({ passwordError: "" });
          }
        } else if (confirmPassword.length > 0 && confirmPassword !== password) {
          this.setState({ confirmPasswordError: "Password doesn't match." });
          if (!strongRegex.test(password)) {
            this.setState({
              passwordError:
                "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
            });
          } else {
            this.setState({ passwordError: "" });
          }
        } else if (confirmPassword === password) {
          this.setState({ passwordError: "" });
          this.setState({ confirmPasswordError: "" });
        }
        break;
      case "confirmPassword":
        if (confirmPassword !== password) {
          this.setState({ confirmPasswordError: "Password doesn't match." });
        } else {
          this.setState({ confirmPasswordError: "" });
        }
        break;
      case "mobileNumber":
        if (mobileNumber.length < 10) {
          this.setState({
            mobileNumberError: "Please input correct mobile number",
          });
        } else {
          this.setState({
            mobileNumberError: "",
          });
        }
        break;
      default:
        break;
    }

    this.setState({
      errors: errors,
    });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      mobileNumber,
      preferredFA,
      agreePolicy,
      showPass,
      showConfirmPass,
      toggleAlert,
      severity,
      messageAlert,
      submitDisabled,
      youtubeId,
      referralCode,
      supportWebp
    } = this.state;

    const isMobile = window.matchMedia("(max-width: 1000px)").matches;

    const hasEmptyFields =
      !firstName || !lastName || !email || !password || !confirmPassword;
    const mobileNumberLimit = this.helpers.phoneNumIsFromPH(mobileNumber)
      ? 12
      : 4;
    const signUpBtnDisabled =
      hasEmptyFields || !agreePolicy || mobileNumber.length < mobileNumberLimit;

    const signupForm = (
      <ValidatorForm onSubmit={this.onSubmit} ref={(r) => (this.form = r)}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextValidator
              placeholder="First name"
              onChange={this.onChange}
              name="firstName"
              value={firstName}
              validators={["required"]}
              variant="outlined"
              inputProps={{
                style: {
                  padding: "12px 0px 12px 12px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              errorMessages={["This field is required."]}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextValidator
              placeholder="Last name"
              onChange={this.onChange}
              name="lastName"
              value={lastName}
              validators={["required"]}
              variant="outlined"
              inputProps={{
                style: {
                  padding: "12px 0px 12px 12px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              errorMessages={["This field is required."]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextValidator
              placeholder="Email address"
              onChange={this.onChange}
              name="email"
              value={email}
              validators={["required", "isEmail"]}
              variant="outlined"
              inputProps={{
                style: {
                  padding: "12px 0px 12px 12px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              errorMessages={[
                "This field is required.",
                "Invalid Email Address",
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              name="password"
              inputProps={{
                style: {
                  padding: "12px 0px 12px 12px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              value={password}
              placeholder="Password"
              autoComplete="off"
              type={showPass ? "text" : "password"}
              showPass={showPass}
              iconButtonOnClick={this.onClickShowPassword}
              iconButtononMouseDown={this.onMouseDownPassword}
              onChange={this.onChange}
              onCut={this.onCopyPasteCut}
              onCopy={this.onCopyPasteCut}
              onPaste={this.onCopyPasteCut}
              validators={["required", "isValidPassword"]}
              errorMessages={[
                "This field is required.",
                "Password should contain at least 1 special character, 1 numerical character, 1 uppercase character, 1 lowercase character. Maximum of 64 characters and minimum of 8 characters",
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              name="confirmPassword"
              inputProps={{
                style: {
                  padding: "12px 0px 12px 12px",
                  backgroundColor: "white",
                  borderRadius: "5px",
                },
              }}
              value={confirmPassword}
              placeholder="Confirm Password"
              autoComplete="off"
              type={showConfirmPass ? "text" : "password"}
              showPass={showConfirmPass}
              iconButtonOnClick={this.onClickConfirmShowPassword}
              iconButtononMouseDown={this.onMouseDownConfirmPassword}
              onChange={this.onChange}
              onCut={this.onCopyPasteCut}
              onCopy={this.onCopyPasteCut}
              onPaste={this.onCopyPasteCut}
              validators={["required", "passwordMatched"]}
              errorMessages={[
                "This field is required.",
                "Password doesn't match.",
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              country={"ph"}
              masks={{
                ph: "(...) ... ....",
              }}
              containerStyle={{
                width: "100%",
              }}
              inputStyle={{
                width: "100%",
                padding: "8px 0px 8px 50px",
              }}
              placeholder="Mobile Number"
              inputClass="mobile-number-input"
              value={mobileNumber}
              specialLabel=""
              excludeCountries = {["us"]}
              onChange={this.onMobileNumber}
            />
          </Grid>
          {!this.props.match.params.referralCode && (
            <Grid item xs={12}>
              <TextValidator
                placeholder="Who is your financial advisor?"
                onChange={this.onChange}
                name="preferredFA"
                value={preferredFA}
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "12px 0px 12px 12px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  },
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginLeft: "15px" }}>
            <FormControlLabel
              control={
                <CheckboxField
                  formName="agreePolicy"
                  checked={agreePolicy}
                  onClick={this.onClickAgreePolicy}
                  checkboxName="agreePolicy1"
                  color="primary"
                  validators={["required"]}
                  errorMessages={[
                    `Agree on the terms and conditon and policy of ${process.env.REACT_APP_PROJECT_NAME}.`,
                  ]}
                  label={<PrivacyPolicyLink firebase={this.props.firebase} />}
                  value={agreePolicy}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            {toggleAlert && (
              <>
                <AlertBox severity={severity} message={messageAlert} />
                <br />
              </>
            )}
            {submitDisabled ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <Button
                variant="contained"
                disabled={signUpBtnDisabled}
                type="submit"
                fullWidth
                color="primary"
                style={{
                  color: "white",
                  fontSize: "13px",
                  padding: "10px 45px",
                }}
              >
                Create my account
              </Button>
            )}
          </Grid>
        </Grid>
      </ValidatorForm>
    );

    return (
      <div
        style={{
          padding: isMobile ? "30px 20px" : "90px 150px 80px",
          background: supportWebp
            ? `linear-gradient(279.45deg, rgba(36, 96, 64, 0.8) -16.18%, rgba(71, 137, 102, 0.8) 34.24%, rgba(255, 255, 255, 0.16) 65.55%), url('${backgroundInitialWebp}')`
            : `linear-gradient(279.45deg, rgba(36, 96, 64, 0.8) -16.18%, rgba(71, 137, 102, 0.8) 34.24%, rgba(255, 255, 255, 0.16) 65.55%), url('${backgroundInitial}')`,
          backgroundPosition: "center bottom",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div
              style={{
                padding: isMobile ? "10px" : "65px 50px",
              }}
            >
              <b
                style={{
                  fontSize: isMobile ? "30px" : "50px",
                  color: "white",
                  textShadow: "1px 3px 2px rgba(0,0,0,.1)",
                }}
              >
                Start Building Wealth with just ₱1,000!
              </b>
              <p
                style={{
                  fontSize: isMobile ? "20px" : "28px",
                  color: "white",
                  width: isMobile ? "100%" : "80%",
                }}
              >
                Open your FREE investment account in 5-10 minutes with only 1
                valid ID.
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <div
              style={{
                padding: isMobile ? "10px" : "20px 90px",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  paddingBottom: "10px",
                  fontSize: isMobile ? "20px" : "28px",
                  color: "white",
                  textShadow: "1px 3px 2px rgba(0,0,0,.1)",
                }}
              >
                Open a Rampver Online Account
              </h2>
              {signupForm}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const SignUpContainer = compose(withRouter, withFirebase)(SignUp);

export default SignUpContainer;
