import React, { Component } from "react";
import { withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import NavigationMenus from "../NavigationMenus";
import {
  Breadcrumbs,
  Tabs,
  Tab,
  Typography,
  Link,
  Fade,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import * as ROUTES from "../../constants/route";
import { TabPanel } from "./TabPanel";
import FundCard from "./FundCard";
import { AuthUserContext } from "../Session";
import Helpers from "../Helpers";
import CustomButton from "../Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SearchFilter from "./SearchFilter";

const useStyles = makeStyles((theme) => ({
  recommendedInvestCardTitle: {
    display: "flex",
    justifyContent: "left",
    textAlign: "left",
    padding: "10px",
    borderRadius: "5px 5px 0px 0px",
    background: "#256141 !important",
    color: "white !important",
    fontWeight: "600",
    fontSize: "12px",
    letterSpacing: "0.015em;",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
  },
  recommendedInvestCardButton: {
    display: "flex",
    justifyContent: "center",
    margin: "5px 10px",
  },
  recommendedInvestCardText: {
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
    },
  },
}));

const MyInvestmentsPage = () => {
  const classes = useStyles();
  const smBreakpoint = useMediaQuery(useTheme().breakpoints.up("sm"));
  return (
    <>
      <NavigationMenus
        content={
          <MyInvestments smBreakpoint={smBreakpoint} classes={classes} />
        }
      />
    </>
  );
};

class MyInvestmentsBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      categories: [],
      recommendedFunds: [],
      individualFunds: [],
      fundRates: [],
      companies: [],
      isInvesting: false,
      tab: 0,
      expanded: false,
      disclaimerOpen: false,
      docId: null,
      expandedCompanies: false,
      searchQuery: "",
      filterBy: "",
      sortingBy: "",
      existingCompanies: [],
      category: false,
      userTier: 0,
      userInvestmentAmount: 0,
      hasValidID: false,
      hasTIN: false,
    };
    this.helpers = new Helpers();
  }

  static contextType = AuthUserContext;

  componentDidMount() {
    window.scrollTo(0, 0);
    let params = this.props.history.location.state;
    if (params !== undefined) this.tabChange("", params.tab);

    this.fetchUser();
  }

  fetchUser = async () => {
    const uid = this.context.uid;
    const usersRef = this.props.firebase.db.collection("users").doc(uid);
    const kycRef = this.props.firebase.db
      .collection("users")
      .doc(uid)
      .collection("KYC")
      .doc("KYC");

    try {
      const [userDataDoc, kycDataDoc] = await Promise.all([
        usersRef.get(),
        kycRef.get(),
      ]);

      const userData = userDataDoc.data();
      const kycData = kycDataDoc.data();

      let userTier = 1;
      let hasValidID = false;
      let hasTIN = false;
      if (kycDataDoc.exists) {
        hasValidID =
          (kycData?.ids?.SSS !== ""
            ? true
            : kycData?.ids?.TIN !== ""
            ? true
            : false) && kycData?.validated === "verified";
            hasTIN =
          ( kycData?.ids?.TIN !== ""
            ? true
            : false) && kycData?.validated === "verified";

        if (kycData?.validated === "pending") {
          hasValidID = userData?.stats?.tier === 2 ? 
                      kycData?.approvedTier2Id !== undefined?
                       kycData?.approvedTier2Id : false : false;
          hasTIN = userData?.stats?.tier === 2 ? 
                      kycData?.ids?.TIN !== "" : false;
          userTier = 2;
          if (!userData?.stats?.tier || userData?.stats?.tier === 1) {
            userTier = 1;
          }
        } else {
          userTier = userData?.stats?.tier ?? 3;
        }
      }

      this.setState({ user: userData, userTier, hasValidID, hasTIN  });
      await this.fetchAllFunds();
    } catch (err) {
      console.log(err);
    }
  };

  fetchAllFunds = async () => {
    try {
      const [fundResult, fundRatesResult] = await Promise.all([
        this.props.firebase.db.collection("funds").orderBy("name", "asc").get(),
        this.props.firebase.db
          .collection("fundRates")
          .orderBy("date", "desc")
          .limit(1)
          .get(),
      ]);

      const fundData = fundResult.docs;
      const latestFundRate = fundRatesResult.docs[0].data();

      const funds = [];
      const existingCompanies = [];

      fundData
        .filter((fund) => fund.data().status)
        .forEach((fund) => {
          const fundName = fund.data().name;
          const fundId = fund.id;

          const fundRateFundIndex = latestFundRate.fundDetails.findIndex(
            (x) => x.fundName === fundName
          );
          console.log(fundRateFundIndex)
          if (
            !existingCompanies.some(
              (x) => x.value === fund.data().fundCompanyDetails.fundCompanyId
            )
          ) {
            existingCompanies.push({
              value: fund.data().fundCompanyDetails.fundCompanyId,
              label: fund.data().fundCompanyDetails.fundCompanyName,
            });
          }

          const requireValidId =
            fund.data().fundStats?.requiredValidId ?? false;
          const markerValidId = fund.data()?.fundType === "UITF" ;

          if(markerValidId && !this.state.hasTIN){ return }
          if ( requireValidId && !this.state.hasValidID) {
            return;
          }

          return funds.push({
            id: fundId,
            name: fundName,
            banner: fund.data().banner,
            riskType: fund.data().riskType,
            minInitialInvestment: fund.data().minInitialInvestment,
            maxInitialInvestment: fund.data().maxInitialInvestment,
            minAdditionalInvestment: fund.data().minAdditionalInvestment,
            date: this.helpers.dateFormat(
              new Date(
                latestFundRate.fundDetails[fundRateFundIndex].date.seconds *
                  1000
              )
            ),
            pricePerShare:
              latestFundRate.fundDetails[fundRateFundIndex].rate || "",
            ytd: latestFundRate.fundDetails[fundRateFundIndex].ytd || "",
            oneyrprogress: fund.data().firstYear || "",
            fundCompany: fund.data().fundCompanyDetails.fundCompanyId,
            fundCode: fund.data().code,
          });
        });

        let summaryAmt = 0;
        if (this.state.user.investmentSummary) {
          const investmentSummary = this.state.user.investmentSummary;
          investmentSummary.forEach((item) => {
            const fund = funds.find((x) => x.fundCode === item.fundCode);
          const rate = latestFundRate.fundDetails.find(
            (x) => x.fundName === fund.name
          );

          summaryAmt += item.value * rate.rate;
        });
      }

      this.setState(
        {
          fundRates: latestFundRate,
          individualFunds: funds,
          existingCompanies: existingCompanies,
          userInvestmentAmount: summaryAmt,
        },
        () => {
          this.fetchRecommendedFunds();
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  fetchCategories = () => {
    const categoryRef = this.props.firebase.db.collection("fundCategories");
    let categories = [];
    categoryRef
      .get()
      .then((snapshot) => {
        const data = snapshot.docs;
        data.forEach((category) => {
          categories.push(category.data());
        });
        this.setState({ categories: categories });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchRecommendedFunds = () => {
    const { user, individualFunds } = this.state;
    if (user !== null) {
      const fundPerRiskProle = [];
      if ("hasLongKYC" in user) {
        individualFunds.forEach((item) => {
          if (item.riskType === user.riskProfile) {
            fundPerRiskProle.push(item);
            this.setState({ recommendedFunds: fundPerRiskProle });
          }
        });
      } else {
        if (individualFunds.length > 3) {
          this.setState({ recommendedFunds: individualFunds.slice(0, 3) });
        } else {
          this.setState({ recommendedFunds: individualFunds });
        }
      }
    }
  };

  fetchCompanies = () => {
    const companyRef = this.props.firebase.db.collection("fundCompanies");
    let companies = [];
    let counter = 0;
    companyRef
      .get()
      .then((snapshot) => {
        const data = snapshot.docs;
        data.forEach((company) => {
          counter++;
          const companyData = company.data();

          if (counter !== data.length) {
            companyData.id = company.id;
            companies.push(companyData);
          } else {
            companyData.id = company.id;
            companies.push(companyData);
            this.setState({ companies: companies });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleExpandCompany = () => {
    const expanded = this.state.expandedCompanies;
    this.setState({ expandedCompanies: !expanded });
  };

  tabChange = (e, newValue) => {
    this.setState({ tab: newValue });
  };

  redirect = () => {
    this.props.history.push("/investments/category");
  };

  handleExpandCategory = () => {
    const expanded = this.state.expanded;
    this.setState({ expanded: !expanded });
  };

  handleInvestAgree = () => {
    this.setState({ disclaimerOpen: false }, () => {
      const filteredFunds = this.state.individualFunds?.filter(fund =>
        this.state.user?.investmentSummary?.some(inv => inv.fundCode === fund.fundCode)
      ).find(fund => fund.name === this.state.docId);

      if (this.state.isInvesting && filteredFunds)
        this.props.history.push(
          ROUTES.FUNDS + "/" + this.state.docId + "/payment"
        )
      else {
        const convertName = this.state.individualFunds?.find((fundID) => fundID.name ===this.state.docId)
        if(convertName) this.props.history.push({
          pathname: ROUTES.FUNDS + "/" + convertName?.id,
          state: { hasModal: true },
        })
         else this.props.history.push(ROUTES.FUNDS + "/" + this.state.docId)
        
        }
    });
  };

  handleInvestDisagree = () => {
    this.setState({ disclaimerOpen: false });
  };

  redirectFund = (id, fundRiskType, isInvesting) => {
    const { user } = this.state;
    this.setState({ docId: id, isInvesting: isInvesting }, () => {
      if (fundRiskType === user.riskProfile || user.riskProfile === undefined) {
        this.handleInvestAgree();
      } else {
        this.setState({ disclaimerOpen: true });
      }
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleCategory = () => {
    if (window.innerWidth <= 768) {
      this.setState((prevState) => ({
        category: !prevState.category,
      }));
    }
  };

  render() {
    document.body.style.backgroundColor = "white";
    const {
      tab,
      recommendedFunds,
      individualFunds,
      disclaimerOpen,
      userTier,
      userInvestmentAmount,
    } = this.state;

    const { smBreakpoint } = this.props;
    const sortByOneYrReturn = this.state.sortingBy === "1yrperformance";
    const modal = (
      <div>
        <Dialog open={disclaimerOpen} onClose={this.handleInvestDisagree}>
          <DialogContent>
            <DialogContentText style={{ fontWeight: "600" }} align="center">
              Based on your risk profile, this fund does not match your risk
              appetite or investment objectives. Would you like to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              text="Proceed"
              buttonTheme="primary"
              onClick={this.handleInvestAgree}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
    const filterAndSortFunds = (fundsArray) => {
      const searchQuery = this.state.searchQuery;

      return fundsArray
        .filter((item) => {
          const oneYrProgressStr = sortByOneYrReturn
            ? parseFloat(item.oneyrprogress * 100).toFixed(2)
            : ""; // this only work if sortby 1-year return exist and use search specific value of 1-year return
          const searchbyRiskType =
            this.state.tab === 0
              ? ""
              : item.riskType.toLowerCase().includes(searchQuery.toLowerCase()); // this only work if search risktype in individualfunds
          const ytdStr = parseFloat(item.ytd * 100).toFixed(2);

          // Check if the search query matches the name, risk type, one-year progress, or year-to-date
          const matchesSearchQuery =
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            searchbyRiskType ||
            item.pricePerShare.toString().includes(searchQuery) ||
            oneYrProgressStr.toString().includes(searchQuery) ||
            ytdStr.toString().includes(searchQuery);

          if (this.state.filterBy.length) {
            const lowerCaseRiskType = this.state.filterBy.toLowerCase();

            return (
              (item.riskType.toLowerCase().includes(lowerCaseRiskType) ||
                item.fundCompany.toLowerCase().includes(lowerCaseRiskType)) &&
              matchesSearchQuery
            );
          }

          return matchesSearchQuery;
        })
        .sort((a, b) => {
          // Sorting by year-to-date high values
          if (this.state.sortingBy === "YTDhighest") {
            return parseFloat(b.ytd) - parseFloat(a.ytd);
          }
          // Sorting by one-year performance
          else if (sortByOneYrReturn) {
            return parseFloat(b.oneyrprogress) - parseFloat(a.oneyrprogress);
          }
          return 0;
        });
    };

    const filteredRecommendedFunds = filterAndSortFunds(recommendedFunds);
    const filteredIndividualFunds = filterAndSortFunds(individualFunds);

    const investDisabled =
      userTier === 1
        ? true
        : userInvestmentAmount > 50000 && userTier === 2
        ? true
        : false;

    return (
      <>
        {modal}
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="textSecondary" href={ROUTES.DASHBOARD}>
            Dashboard
          </Link>
          <Typography color="textPrimary" aria-current="page">
            Funds
          </Typography>
        </Breadcrumbs>
        <Fade in={true}>
          <div className="body-content">
            <div className="select-investments">
              <div className="recommended-funds">
                <h1>Recommended Funds</h1>
                <br /> <br />
                <div>
                  <Tabs
                    value={tab}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={this.tabChange}
                    className="custom-tabs"
                  >
                    <Tab label="For You" />
                    <Tab label="All Funds" />
                  </Tabs>
                  <SearchFilter
                    changeCategory={this.handleCategory}
                    valueCategory={this.state.category}
                    companies={this.state.existingCompanies}
                    valueSearch={this.state.searchQuery}
                    onChange={this.handleChange}
                    valueFilter={this.state.filterBy}
                    valueSorting={this.state.sortingBy}
                    riskTypeValidation={this.state.tab === 0}
                  />

                  {/* <Divider /> */}
                  <TabPanel value={tab} index={0}>
                    <Fade in={true}>
                      <FundCard
                        oneYrProgress={sortByOneYrReturn}
                        funds={filteredRecommendedFunds}
                        redirectFund={this.redirectFund}
                        smBreakpoint={smBreakpoint}
                        classes={this.props.classes}
                        investDisabled={investDisabled}
                      />
                    </Fade>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Fade in={true}>
                      <FundCard
                        oneYrProgress={sortByOneYrReturn}
                        funds={filteredIndividualFunds}
                        redirectFund={this.redirectFund}
                        smBreakpoint={smBreakpoint}
                        classes={this.props.classes}
                        investDisabled={investDisabled}
                      />
                    </Fade>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </>
    );
  }
}

const condition = (authUser) => !!authUser;

const MyInvestments = compose(
  withAuthorization(condition),
  withRouter,
  withFirebase
)(MyInvestmentsBase);

export default MyInvestmentsPage;

export { MyInvestments };
