import React from "react";
import { FormControl, Fade, FormHelperText } from "@material-ui/core";
import { ValidatorComponent } from "react-material-ui-form-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "../../App.css";

class MobileNumberField extends ValidatorComponent {
  renderValidatorComponent() {
    const { value, onChange } = this.props;
    return (
      <div className="mt-0 lg:mt-2">
        
        <FormControl fullWidth>
          
          {/* <OutlinedInput
            placeholder="10 digits mobile number"
            error={this.errorText() !== null ? true : false}
            name={name}
            value={value}
            autoComplete="off"
            inputProps={{
              style: { padding: "12px 0px 12px 12px", backgroundColor: 'white' },
              maxLength: 10,
            }}
            startAdornment={
              <InputAdornment position="start">
                +63
              </InputAdornment>
            }
            onChange={onChange}
          /> */}
          <PhoneInput
            country={"ph"}
            masks={{
              ph: "(...) ... ....",
            }}
            containerStyle={{
              width: "100%",
            }}
            inputStyle={{
              width: "100%",
              padding: "12px 0px 12px 50px",
            }}
            placeholder="Mobile Number"
            inputClass="mobile-number-input"
            value={value}
            specialLabel=""
            onChange={onChange}
            excludeCountries = {["us"]}
          />
        </FormControl>
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;
    // const { value } = this.props;

    if (isValid) {
      return null;
    }

    return (
      <Fade in={true}>
        <FormHelperText style={{ color: "red" }}>
          {this.getErrorMessage()}
        </FormHelperText>
      </Fade>
    );
  }
}

export default MobileNumberField;
